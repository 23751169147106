<template>
  <div id="app">
    <Header />
    <keep-alive>
      <router-view />
    </keep-alive>
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
export default {
  name: "App",
  components: { Header, Footer },
  data() {
    return {};
  },
};
</script>

<style>
@import url("./assets/css/public.css");
#app {
  width: 100%;
  padding-bottom: 0;
}
</style>
