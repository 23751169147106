<template>
  <section class="header" v-if="activeIndex">
    <div class="content">
      <img src="../assets/img/header/logo.png" alt="" />
      <div class="right">
        <div v-for="(item, index) in routesList" :key="index" :class="['item', index === activeIndex ? 'activeItem' : '']" @click="toPage(item.path)">
          {{ item.name }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      routesList: [
        {
          path: "/home",
          name: "首页",
        },
        {
          path: "/product",
          name: "产品介绍",
        },
        {
          path: "/case",
          name: "案例展示",
        },
        {
          path: "/about",
          name: "联系我们",
        },
      ],
      activeIndex: -1,
    };
  },
  watch: {
    $route(to) {
      switch (to.path) {
        case "/home":
          this.activeIndex = 0;
          break;
        case "/product":
          this.activeIndex = 1;
          break;
        case "/case":
        case "/relation":
          this.activeIndex = 2;
          break;
        case "/about":
          this.activeIndex = 3;
          break;
      }
    },
  },
  methods: {
    toPage(path) {
      if (this.$route.path === path) return;
      this.$router.push(path);
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  display: flex;
  height: 90px;
  align-items: center;
  justify-content: center;
  width: 100%;
  .content {
    display: flex;
    height: inherit;
    justify-content: space-between;
    align-items: center;
    width: 1436px;
    background: white;
    img {
      height: 72px;
    }
    .right {
      margin-left: auto;
      display: flex;
      .item {
        height: 90px;
        padding: 0 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
      }
      .activeItem {
        font-weight: bold;
        background: #f6f6f6;
        color: #1b3a81;
      }
    }
  }
}
</style>
