import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    name: "home",
    path: "/home",
    meta: {
      title: "首页",
    },
    component: () => import("../view/home.vue"),
  },
  {
    name: "product",
    path: "/product",
    meta: {
      title: "产品介绍",
    },
    component: () => import("../view/product.vue"),
  },
  {
    name: "case",
    path: "/case",
    meta: {
      title: "案例展示",
    },
    component: () => import("../view/case.vue"),
  },
  {
    name: "relation",
    path: "/relation",
    meta: {
      title: "案例展示详情",
    },
    component: () => import("../view/relation.vue"),
  },
  {
    name: "about",
    path: "/about",
    meta: {
      title: "联系我们",
    },
    component: () => import("../view/about.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  document.title = `特磊网络—${to.meta.title}`;
  next();
});

export default router;
