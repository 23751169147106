import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import BaiduMap from "vue-baidu-map";
import animated from "animate.css"; // npm install animate.css --save安装，在引入

Vue.config.productionTip = false;
Vue.use(BaiduMap, {
  ak: "nblAgDR8asULI1Rga98YiQVTSlRDXb4h",
});
Vue.use(animated);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
