<template>
  <section class="footer">
    <div class="content">
      <img class="logo" src="@/assets/img/header/logo.png" alt="" />
      <div class="about">
        <div class="aboutTitle">导航</div>
        <div class="flex">
          <div class="item" @click="toPage('/product')">产品介绍</div>
          <div class="item" @click="toPage('/case')">案例展示</div>
          <div class="item" @click="toPage('/about')">联系我们</div>
        </div>
      </div>
      <div class="about">
        <div class="aboutTitle">公司地址</div>
        <div class="flex">
          <img class="icon" src="@/assets/img/footer/address.png" alt="" />
          <div class="address">中国·广东·深圳：广东省深圳市宝安区 西乡街道万庭大厦3号楼1702</div>
        </div>
      </div>
    </div>
    <div class="aq">
      Copyright@ 2009- 2020 浙江特磊网络有限公司深圳分公司
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" class="link">粤ICP备2022079988号</a>
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" class="link">粤ICP备2022079988号-1</a>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    toPage(path) {
      if (this.$route.path === path) return;
      this.$router.push(path);
    },
  },
};
</script>

<style scoped lang="scss">
.footer {
  width: 100%;
  height: 288px;
  background: #f6f6f6;
  overflow: hidden;
  .content {
    padding: 0 243px;
    display: flex;
    height: 228px;
    align-items: center;
    .logo {
      width: 235px;
      height: 72px;
      margin-right: 215px;
    }
    .about {
      height: 228px;
      padding-top: 64px;
      &:last-child {
        margin-left: 220px;
      }
      .aboutTitle {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 22px;
      }
      .item {
        flex-shrink: 0;
        margin-right: 96px;
        color: #666666;
        font-size: 18px;
      }
      .address {
        color: #666666;
      }
      .icon {
        width: 16px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
  .aq {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ececec;
    font-size: 14px;
    color: #333;
  }
}
.link {
  margin-left: 4px;
  text-decoration: none;
  color: #333;
}
</style>
